<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<span class="text-h5">Add new field</span>
		</q-card-section>
		<q-card-section>
			<q-item>
				<q-input
					v-model="new_field.label"
					class="full-width"
					label="Field label"
					:error="has_label_error"
					:error-message="label_error_message"
				/>
			</q-item>
			<q-item>
				<q-select
					v-model="new_field.field_type"
					:options="field_types"
					class="full-width"
					label="Field type"
					:error="has_field_error"
					:error-message="field_error_message"
				/>
			</q-item>
			<q-item class="justify-end" teg="label">
				<q-toggle
					v-model="new_field.is_pii"
					label="Personal Data"
					left-label
				>
					<q-tooltip anchor="top middle" :delay="500">
						Personally Identifiable Information
					</q-tooltip>
				</q-toggle>
			</q-item>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" @click="$emit('close', false)" />
			<q-btn flat label="Add" color="primary" @click="addField"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";
import lexorder from "@/services/lexorder.js";

export default {
	name: "IncidentFieldAdd",
	data() {
		return {
			new_field: {
				label: "",
				field_type: "",
				is_pii: false,
				order: ''
			},
			has_label_error: false,
			has_field_error: false,
			label_error_message: '',
			field_error_message: '',

		};
	},
	props: {
		fields: Array,
		field_types: Array,
	},
	methods: {
		async addField() {
			this.has_field_error = this.has_label_error = false
			this.field_error_message = this.label_error_message = ''
			if (this.hasErrors()){ return }

			this.new_field.order = lexorder.generate(this.fields);
			const response = await IncidentFieldsAPI.addField(
				this.new_field.label,
				this.new_field.field_type,
				this.new_field.is_pii,
				this.new_field.order,
			);

			if (response.error) {
				if (response.error_message == 'The Field already exists. Please enter different name.'){
					this.has_label_error = true
					this.label_error_message = response.error_message
					return
				}
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.fields.push(response.data);
			this.$q.notify('The field was created successfully.')
			this.$emit('close', true)
		},
		hasErrors(){
			if (!this.new_field.label){
				this.has_label_error = true
				this.label_error_message = 'Please enter a label'
				return true
			}
			if (!this.new_field.field_type){
				this.has_field_error = true
				this.field_error_message = 'Please select a field'
				return true
			}
			return false
		}
	},
};
</script>
