import { render, staticRenderFns } from "./FieldTypeChange.vue?vue&type=template&id=324907ae&scoped=true&"
import script from "./FieldTypeChange.vue?vue&type=script&lang=js&"
export * from "./FieldTypeChange.vue?vue&type=script&lang=js&"
import style0 from "./FieldTypeChange.vue?vue&type=style&index=0&id=324907ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324907ae",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QList,QCardActions,QBtn});qInstall(component, 'directives', {ClosePopup});
