<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center q-pb-none">
			<span class="text-h5">Are you sure you want to delete this field?</span>
		</q-card-section>
		<q-card-section>
			<div v-if="(field_groups_delete.length > 0 || conditions_delete.length > 0)">
				Will be removed from
			</div>
			<div class="row">
				<div v-if="(field_groups_delete.length > 0) && !conditions_only" class="col-sm-4 col-12 wrap">
					<div class="items-center q-pl-none">
						<b>{{field_groups_delete.length}}&nbsp;</b>
						<span v-if="field_groups_delete.length == 1"> field group:<br></span>
						<span v-else> field groups:<br></span>
					</div>
					<div class="border-left q-pl-sm">
						<q-list v-for="field_group in field_groups_delete" :key="field_group.id">
							<div>
								{{field_group.title}} <br>
							</div>
						</q-list>
					</div>
				</div>

				<div v-if="(conditions_delete.length > 0)" class="col-sm-4 col-12">
					<div class="items-center q-pl-none">
						<b>{{conditions_delete.length}}&nbsp;</b>
						<span v-if="conditions_delete.length == 1"> condition:<br></span>
						<span v-else> conditions:<br></span>
					</div>
					<div class="border-left q-pl-sm">
						<q-list v-for="field_group in conditions_delete" :key="field_group.id">
							<div>
								{{field_group.title}}
							</div>
						</q-list>
					</div>
				</div>

				<div v-if="rules_for_field.length > 0" class="col-sm-4 col-12">
					<div class="items-center q-pl-none">
						<b>{{rules_for_field.length}}&nbsp;</b>
						<span v-if="rules_for_field.length == 1"> rule:<br></span>
						<span v-else> rules:<br></span>
					</div>
					<div class="border-left q-pl-sm">
						<div v-for="rule in rules_for_field" :key="rule.id">
							<div tag="label" class="full-width items-center">
								{{rule.form_name}} - {{rule.rule_name}}<br>
							</div>
						</div>
					</div>
				</div>
			</div>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn flat label="Confirm" color="primary" @click="confirm()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";

export default {
	name: "FieldDelete",
	data() {
		return {
			field_groups_delete: [],
			conditions_delete: [],
			rules_for_field: [],
		}
	},
	props: {
		field_id: Number,
		disabled: Boolean,
		conditions_only: Boolean,
	},
	methods: {
		async getData() {
			if (!this.disabled) {
				let response = await IncidentFieldsAPI.getRulesForId(this.field_id);
				this.rules_for_field = response.field_rules;
				let field_appearances = await IncidentFieldsAPI.getFieldAppearances(this.field_id)
				if(!field_appearances.error){
					if(!this.conditions_only){
						this.field_groups_delete = field_appearances.field_groups;
					}
					this.conditions_delete = field_appearances.conditions;
		
					this.short_warning = field_appearances.short_message;
				}
			}
		},
		async confirm() {
			this.conditions_delete.forEach(async element => {
				let new_conditions = []
				element.display_conditions.forEach(async element => {
					if (element["field_id"] !== this.field_id) {
						new_conditions.push(element);
					}		
				});
			});
			this.$emit('iconClicked');
		},
	},
	async created() {
		await this.getData()
	},
}
</script>
<style lang="scss" scoped>
	.border-left{
		border-left: 3px double $grey-5;
	}
	.wrap{
		flex-wrap: wrap;
	}
</style>