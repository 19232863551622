<template>
	<div
		:class="{
			'device-wrapper': true,
			'bg-grey-3': field.is_inherited,
			'bg-grey-1': !field.is_inherited,
			'q-py-md': true,
			'q-px-sm': true,
			'q-mb-md': true,
			'rounded-borders': true,
			row: true,
			'relative-position': true,
		}"
	>
		<div
			:class="{
				'col-12': true,
				'col-md-5': true,
				'q-px-sm': true,
			}"
		>
			<q-input
				label="Field label"
				outlined
				v-model="field.label"
				:disable="field.is_inherited"
				:error="has_label_error"
				:error-message="label_error_message"
			/>
		</div>
		<div
			:class="{
				'col-12': true,
				'col-md-5': true,
				'q-px-sm': true,
			}"
		>
			<q-select
				label="Field type"
				v-model="field.field_type"
				:options="field_types"
				:disable="field.is_inherited"
				:error="has_field_error"
				:error-message="field_error_message"
				/>
		</div>
		<div class="col-12 col-md-2 text-right">
			<div>
				<q-toggle
					v-model="field.is_pii"
					label="Personal Data"
					left-label
					:disable="field.is_inherited"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Personally Identifiable Information
					</q-tooltip>
				</q-toggle>
			</div>
		</div>

		<div class="col-12 q-mt-md">
			<div class="row justify-between items-center q-py-sm device-btns-wrapper">
				<div>
					<q-btn
						flat
						round
						color="dark"
						class="handle"
						icon="o_drag_handle"
						:disable="field.is_inherited"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Drag and drop
						</q-tooltip>
					</q-btn>
				</div>
				<div class="flex justify-end">
					<q-btn
						outline
						color="primary"
						class="q-mr-md"
						icon-right="save"
						label="Save"
						@click="onFieldChange"
						:disable="field.is_inherited"
					/>
					<q-btn
						outline
						color="primary"
						icon-right="delete"
						label="Delete"
						:disable="field.is_inherited"
						@click="delete_dialog=true"
					/>
				</div>
			</div>
		</div>
		<div
			v-if=" ['SINGLESELECT', 'MULTISELECT'].includes(field.field_type) || field.options.length"
			class="full-width"
		>
			<q-separator />
			<div class="row q-pa-sm justify-between relative-position full-width">
				<div class="col-12">
					<FieldOptions
						:field="field"
						:disable="field.is_inherited"
						:options_error_message="options_error_message"
						@reload-fields="reloadFields"
					/>
				</div>
			</div>
		</div>
		<q-dialog v-model="show_popup">
			<FieldTypeChange
				:field="field"
				:rules_for_field="rules_for_field"
				:conditions_for_field_group="conditions_for_field_group"
				:disabled="field.is_inherited"
				@confirm="deleteRulesAndConditions"
			/>
		</q-dialog>
		<q-dialog v-model="delete_dialog">
			<FieldDelete
				:field_id="field.id"
				:disabled="field.is_inherited"
				@iconClicked="deleteField(field.id)"
			/>
		</q-dialog>
	</div>
</template>

<script>
import FieldOptions from "@/components/incidents/fields/FieldOptions.vue";
import FieldDelete from "@/components/incidents/fields/FieldDelete.vue";
import FieldTypeChange from "@/components/incidents/fields/FieldTypeChange.vue";
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";

export default {
	name: "IncidentField",
	components: {
		FieldOptions,
		FieldDelete,
		FieldTypeChange
	},
	data (){
		return{
			current_field : {},
			rules_for_field: [],
			conditions_for_field_group: [],
			current_field_pii: false,
			show_popup: false,
			delete_dialog: false,
			has_field_error: false,
			has_label_error: false,
			field_error_message: '',
			label_error_message: '',
			options_error_message: '',
			current_field_label: '',
		}
	},
	props: {
		field: Object,
		field_types: Array,
	},
	methods: {
		reloadFields(){
			this.$emit('reload-fields')
		},
		async deleteField(id) {
			this.delete_dialog = false
			this.options_error_message = ''
			if (this.field.options.length){
				this.delete_dialog = false
				this.options_error_message = 'Please remove the options for the field first.'
				this.notify(this.options_error_message)
				return
			}
			this.$emit('deleteField', id);
		},
		async deleteRulesAndConditions(){
			let rules_to_be_deleted = []
			let conditions_to_be_deleted = []
			for(let rule of this.rules_for_field){
				rules_to_be_deleted.push(rule.rule_id)
			}

			if (rules_to_be_deleted.length){
				let response_rules = await IncidentFieldsAPI.delete_rules(this.field.id, rules_to_be_deleted);
				if(response_rules.error){
					this.show_popup = false;
					this.notify(response_rules.error_message)
					return
				}
			}

			for(let condition of this.conditions_for_field_group){
				conditions_to_be_deleted.push(condition.id)
			}
			if (conditions_to_be_deleted.length){
				let response_conditions = await IncidentFieldsAPI.delete_conditions(this.field.id, conditions_to_be_deleted);
				if(response_conditions.error){
					this.show_popup = false;
					this.notify(response_conditions.error_message)
					return
				}
			}
			this.updateField();
			this.show_popup = false;
		},

		async onFieldChange() {
			if(
				this.current_field == this.field.field_type &&
				this.current_field_label == this.field.label &&
				this.current_field_pii == this.field.is_pii
			){
				this.$q.notify('There are no changes made to the fiels.')
				return
			}

			let validated = this.validateField(
				this.current_field,
				this.field.field_type,
				this.field.options.length,
				this.field.label
			);

			if(!validated){	return }

			let response = await IncidentFieldsAPI.getRulesForId(this.field.id);
			if (response.error){
				this.notify(response.error_message)
				return
			}
			this.rules_for_field = response.field_rules;

			let field_appearances = await IncidentFieldsAPI.getFieldAppearances(this.field.id)
			if (response.error){
				this.notify(response.error_message)
				return
			}
			this.conditions_for_field_group = field_appearances.conditions

			if(this.rules_for_field.length || this.conditions_for_field_group.length){
				this.show_popup = true;
			}else{
				this.updateField();
			}
		},
		async updateField(){
			const response = await IncidentFieldsAPI.updateField(
				this.field.id,
				this.field.label,
				this.field.field_type,
				this.field.is_pii
			)
			if (response.error) {
				this.notify(response.error_message)
				return
			}
			this.$q.notify('Field updated successfully.')
		},
		validateField(current_field, field_type, options, label){
			this.has_field_error = this.has_label_error = false
			this.field_error_message = this.label_error_message = this.options_error_message = ''

			if (!field_type){
				this.has_field_error = true
				this.field_error_message = "Please select field type."
				return false;
			}

			if (!label){
				this.has_label_error = true
				this.label_error_message = "Please enter label name."
				return false;
			}

			let selects = ["SINGLESELECT","MULTISELECT"];
			if (selects.includes(current_field) && !selects.includes(field_type) && options){
				this.options_error_message = 'Please remove the options for the field first.'
				this.notify(this.options_error_message)
				return false;
			}
			return true;
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	created(){
		this.current_field = JSON.parse(JSON.stringify(this.field.field_type));
		this.current_field_label = this.field.label;
		this.current_field_pii = this.field.is_pii
	}
}
</script>
<style lang="scss" scoped>
	.device-wrapper {
		border: 1px solid $grey-4;
	}
	.device-btns-wrapper {
		border-top: 1px solid $grey-4;
	}
</style>
