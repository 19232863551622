<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center q-pb-none">
			<span class="text-h5">Are you sure you want to change the type of this field?</span>
		</q-card-section>
		<q-card-section class="q-pb-none row">
			<div v-if="rules_for_field && rules_for_field.length > 0" class="col-sm-6 col-12 wrap">
				<div>Will be removed from {{ rules_for_field.length }}
					<span v-if="rules_for_field.length == 1">rule</span>
					<span v-if="rules_for_field.length > 1">rules</span>
				</div>
				<div class="border-left q-pl-sm">
					<q-list v-for="rule in rules_for_field" :key="rule.id">
						<div> {{ rule.form_name }} - {{ rule.rule_name }} </div>
					</q-list>
				</div>
			</div>
			<div v-if="conditions_for_field_group && conditions_for_field_group.length > 0" class="col-sm-6 col-12 wrap">
				<div>Will be removed from {{ conditions_for_field_group.length }}
					<span v-if="conditions_for_field_group.length == 1">field group condition</span>
					<span v-if="conditions_for_field_group.length > 1">field group conditions</span>
				</div>
				<div class="border-left q-pl-sm">
					<q-list v-for="condition in conditions_for_field_group" :key="condition.id">
						<div> {{ condition.title }} </div>
					</q-list>
				</div>
			</div>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" v-close-popup/>
			<q-btn flat label="Confirm" color="primary" @click="$emit('confirm')"/>
		</q-card-actions>
	</q-card>
</template>
<script>
export default {
	name: "FieldTypeChange",
	props: {
		field: Object,
		rules_for_field: Array,
		conditions_for_field_group: Array,
	},
}
</script>
<style lang="scss" scoped>
	.border-left{
		border-left: 3px double $grey-5;
	}
	.wrap{
		flex-wrap: wrap;
	}
</style>