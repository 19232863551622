<template>
	<q-card>
		<q-card-section class="text-center">
			<span
				>Are you sure you want to delete <b>{{ option.value }}</b
				>?</span
			>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Delete" color="primary" @click="$emit('delete', option.id)" />
		</q-card-actions>
	</q-card>
</template>

<script>
export default {
	name: "FieldOptionDelete",
	props: {
		option: Object,
	}
}
</script>