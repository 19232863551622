<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Fields
		</h1>
		<draggable
			v-model="fields"
			v-bind="dragOptions"
			handle=".handle"
			@start="drag = true"
			@end="drag = false"
			@sort="sort"
		>
			<q-list v-for="field in fields" :key="field.id">
				<IncidentField
					:field="field"
					:field_types="field_types"
					@deleteField="deleteField"
					@reload-fields="reloadFields"
				/>
			</q-list>
		</draggable>

		<q-dialog v-model="add_dialog">
			<IncidentFieldAdd
				:fields="fields"
				:field_types="field_types"
				@close="closeAddField"
			/>
		</q-dialog>

		<div class="field-fab-wrapper q-pt-md">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="add"
				label="Add Field"
				rounded
				@click="() => (add_dialog = true)"
			/>
			<q-btn
				v-else
				size="lg"
				color="primary"
				icon="add"
				round
				@click="() => (add_dialog = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add Field
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>
import IncidentField from "@/components/incidents/fields/Field.vue";
import IncidentFieldAdd from "@/components/incidents/fields/FieldAdd.vue";
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";
import lexorder from "@/services/lexorder.js"
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
	name: "Fields",
	components: {
		draggable,
		IncidentField,
		IncidentFieldAdd,
	},
	data() {
		return {
			fields: [],
			field_types: [],
			drag: false,
			add_dialog: false,
		};
	},
	computed: {
		...mapGetters(['logged_user', 'current_CG']),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
				scrollSensitivity: 200,
				forceFallback: true,
			};
		}
	},
	methods: {
		async reloadFields(){
			await this.getFields()
		},
		closeAddField(created){
			this.add_dialog = false
			if (created){
				setTimeout(function() {
					window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
				}, 100);
			}
		},
		async deleteField(id) {
			const response = await IncidentFieldsAPI.deleteField(id);
			if(!response.error){
				this.fields = this.fields.filter(field => field.id !== id);
				this.$q.notify('The field was deleted successfully.')
			}
		},
		async sort(event) {
			let curr = this.fields[event.newIndex];
			curr.order = lexorder.update(event.newIndex, this.fields);
			await IncidentFieldsAPI.patchField(curr.id, { order: curr.order });
		},
		async getFields(){
			this.$q.loading.show({
				delay: 400,
			})
			this.fields = await IncidentFieldsAPI.getFields();
			if(this.fields.error){
				this.fields = []
			}else{
				this.field_types = await IncidentFieldsAPI.getFieldChoices();
				if(this.field_types.error){
					this.field_types = []
				}
			}
			for (let field of this.fields) {
				field['is_inherited'] =
					this.current_CG.customer_group.parent && field.customer_group !== this.logged_user.customer_group
			}
			this.$q.loading.hide()
		},
	},
	async created() {
		await this.getFields()
	}
};
</script>
