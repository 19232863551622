<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<span class="text-h5">Add new option for {{ field.label }}</span>
		</q-card-section>
		<q-card-section>
			<q-input
				label="Field option"
				bottom-slots
				v-model="new_option"
				:error="has_new_option_error"
				:error-message="new_option_error_msg"
			/>
		</q-card-section>
		<q-card-actions>
			<q-btn
				label="Add Option"
				color="primary"
				class="full-width"
				@click="addOption"
			/>
		</q-card-actions>
	</q-card>
</template>

<script>
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";
export default {
	name: "FieldOptionAdd",
	data() {
		return {
			new_option: '',
			has_new_option_error: false,
			new_option_error_msg: ''
		}
	},
	props: {
		field: Object,
	},
	methods: {
		async addOption(){
			this.has_new_option_error = false
			this.new_option_error_msg = ''
			if (!this.new_option){
				this.has_new_option_error = true
				this.new_option_error_msg = 'Please enter a field option value'
				return
			}
			const response = await IncidentFieldsAPI.createOption(this.field.id, this.new_option)
			if (response.error){
				this.$emit('close')
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$emit('close')
			this.$emit('reload-fields')
			this.$q.notify('Field option created successfully.')
		}
	},
}
</script>