<template>
	<div @click.stop>
	<div class="row justify-between">
		<div class="row items-center">
			<span class="q-mr-sm">{{ value }}</span>
			<q-icon name="edit" />
		</div>
		<q-btn
			flat
			icon="delete"
			color="primary"
			:disable="disable"
			@click.stop="delete_option_modal=true"
		/>
	</div>
	<q-popup-edit v-model="value" auto-save>
		<template v-slot="scope">
			<q-input
				v-model="scope.value"
				dense
				autofocus
				@keyup.enter="scope.cancel"
				:disable="disable"
			>
				<template v-slot:after>
					<q-btn
						flat
						icon="delete"
						color="primary"
						:disable="disable"
						@click.stop="delete_option_modal=true"
					/>
				</template>
			</q-input>
		</template>
	</q-popup-edit>
	<q-dialog v-model="delete_option_modal">
		<FieldOptionDelete
			:option="option"
			@close="delete_option_modal=false"
			@delete="deleteOption(option.id)"
		/>
	</q-dialog>

</div>
</template>

<script>
import FieldOptionDelete from "@/components/incidents/fields/FieldOptionDelete.vue";

export default {
	name: "FieldOption",
	components: {
		FieldOptionDelete
	},
	data() {
        return {
			show_options: false,
			new_option_error: "",
			delete_option_modal: false,
		}
	},
	props: {
		option: Object,
		disable: Boolean,
	},
	computed: {
		value: {
			get() {
				return this.option.value
			},
			async set(val) {
				this.$emit('update-value', this.option.id, val)
			},
		},
	},
	methods: {
		async deleteOption(id) {
			this.delete_option_modal = false
			this.$emit('delete', id)
		},
	},
};
</script>
