<template>
	<div>
		<q-expansion-item
			ref="list"
			expand-separator
			icon="edit_note"
			label="Field Options"
			:class="{'error-options-list' : options_error_message, 'text-left' : true}"
		>
			<q-btn
				class="q-my-md"
				color="primary"
				flat
				icon="add"
				label="Add New Option"
				:disable="disable"
				@click="add_option_modal=true"
			/>
			<q-list :bordered="field.options && field.options.length ? true : false">
				<q-item
					v-for="(option, index) in field.options"
					:clickable="disable ? false : true"
					:key="option.id"
					@click="openFieldOptionEdit(index)"
				>
					<q-item-section>
						<FieldOption
							:option="option"
							@delete="deleteOption"
							@update-value="editOption"
							ref="field_options"
							v-on="$listeners"
							:disable="disable"
						/>
					</q-item-section>
				</q-item>
			</q-list>
		</q-expansion-item>
		<q-dialog v-model="add_option_modal">
			<FieldOptionAdd
				:field="field"
				@close="add_option_modal=false"
				@reload-fields="reloadFields"
			/>
		</q-dialog>
	</div>
</template>

<script>
import FieldOption from "@/components/incidents/fields/FieldOption.vue";
import FieldOptionAdd from "@/components/incidents/fields/FieldOptionAdd.vue";
import IncidentFieldsAPI from "@/services/api/IncidentFields.js";

export default {
	name: "IncidentFieldOptions",
	components: {
		FieldOption,
		FieldOptionAdd,
	},
	data() {
		return {
			add_option_modal: false,
		}
	},
	props: {
		field: Object,
		disable: Boolean,
		options_error_message: String,
	},
	methods: {
		async deleteOption(id) {
			const response = await IncidentFieldsAPI.deleteOption(id)
			if (response.error){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.field.options = this.field.options.filter(option => option.id !== id)
			this.$q.notify('Field option deleted successfully.')
		},
		async editOption(option_id, option_value) {
			this.$q.loading.show({
					delay: 400,
				})
				const response = await IncidentFieldsAPI.updateOption(option_id, this.field.id, option_value);
				this.$q.loading.hide()
				if (response.error) {
					this.$q.notify({
						timeout: 6700,
						message: response.error_message,
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
			this.$emit('reload-fields')
			this.$q.notify('Field option updated successfully.')
		},
		reloadFields(){
			this.$emit('reload-fields')
		},
		openFieldOptionEdit(index) {
			this.$refs.field_options[index].$el.click()
		},
	},
};
</script>
<style lang="scss" scoped>
.error-options-list {
	border: 1px solid $negative;
}

</style>
